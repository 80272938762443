import React, { useState } from 'react';
import axios from 'axios';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from "gatsby";
import Config from '../../config/config'
import {Spinner} from 'react-bootstrap';

function RegisterForm(props) {

    const [email, setEmail] = useState('');
    const [webshopURL, setWebshopURL] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState({});
    const [hidePassword, setHidePassword] = useState(true);

    const [loading, setLoading] = useState(false);

    function register(evt) {
        evt.preventDefault();

        // Request API.
        // Add your own code here to customize or restrict how the public can register new users.
        axios.post( Config.apiURL + '/auth/local/register', {
            username: email,
            email: email,
            password: password,
            additional_data_filled: true,
            additional_data: {
                webshop_domain: webshopURL,
            }
        })
        .then(response => {
            // Handle success.
            navigate("/profile-authentication", { state:{ registration:true } });
        })
        .catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
            setError(prev => { return {...prev, response: 'A regisztráció nem sikerült, a felhasználó vagy e-mail már foglalt !'}} );
        });
    };

    return (
        <div className="register-form" id="register-form">
            <div className="section-title">
                <h2>Készíts egy ingyenes felahsználói fiókot</h2>
            </div>
            {error.response && <div className="error-message-container">
                <p style={{textAlign:'center', color:'red', padding:'15px'}}>{error.response}</p>
            </div>}
            <div>
            {/* TODO: form check is runing, do we need email check in passwordChecker */}
                <form id="registration-form" onSubmit={register}>
                    <div className="form-group">
                        <input required type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={(evt) => setEmail(evt.target.value)} />
                        {error.email && <p className="error-message">{error.email}</p>}
                    </div>
                    <div className='form-group'>
                        <input required type="text" name="webshop_url" className="form-control" placeholder="Webshop URL" value={webshopURL} onChange={(evt) => setWebshopURL(evt.target.value)} />
                    </div>
                    <div className="form-group">
                        <input required type={hidePassword ? "password" : 'text'} name="password" className="form-control" placeholder="Jelszó" value={password} onChange={(evt) => setPassword(evt.target.value)} />
                        <FontAwesomeIcon title="Jelszó megjelenítése" role="button" onClick={() => setHidePassword(!hidePassword)} className="show-pass"  icon={!hidePassword ? faEyeSlash : faEye} />
                        {error.password && <p className="error-message">{error.password}</p>}
                    </div>

                    <button type="submit" form="registration-form" >
                        {loading ? <Spinner animation="border" role="status" /> : "Regisztráció"}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default RegisterForm
