import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { graphql, useStaticQuery } from "gatsby"
import Image from 'gatsby-image';
import RegisterForm from '../ProfileAuthentication/RegisterForm';
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';
import { connect } from "react-redux";
import { getIsLogedIn } from '../../state/createStore';

const query = graphql`
{
    strapiHeader {
        header_text
        header_title
        headerImage {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
      }
}
`

function Header(props) {

    const data = useStaticQuery(query);

    return (
        <Row className="header-container">
            <Col className="image-container" style={props.login ? {marginRight: 0, marginBottom: 30} : null}>
                <Image fluid={data.strapiHeader.headerImage.childImageSharp.fluid} />
                <h1>{data.strapiHeader.header_title}</h1>
                <ReactMarkdown children={data.strapiHeader.header_text} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
            </Col>
            {!props.login ?
                <Col id="register" className="register-container">
                    <RegisterForm header_title={data.strapiHeader.header_title} />
                </Col>
                : null
            }
        </Row>
    );
}

const mapStateToProps = (state) => ({
    login: getIsLogedIn(state),
});

export default connect(
    mapStateToProps,
    null
)(Header)